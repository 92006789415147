<template>
    <div>
        <v-main fluid class="mt-n6">
            <div v-if="!OperationSuccessful">
                <v-form ref="form"
                        v-model="valid"
                        :lazy-validation="lazy"
                        class="w-100">
                    <v-container fluid>
                        <v-row class="d-flex justify-content-center">
                            <v-col cols="12" sm="10" md="10" lg="10">
                                <div class="bg-white border p-5 mt-5">

                                    <h1 class="display-3">{{ $t("Applicationform") }}</h1>
                                    <small class="text-muted">{{ $t("IM_NewSuppliersCanRegisterOnThisPage") }}</small>
                                    <br />
                                    <br />
                                    <dynoform @change="UpdateFormField($event, 'formdata')"
                                              @changeGrid="UpdateExtData($event, $event.tableName)"
                                              @click="FormButtonClicked($event)"
                                              :attributes="GetFormAttributes"
                                              @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                              @HeaderButtonClicked="HeaderButtonClickedDyno($event)"
                                              @changeAttribute="ChangeAttribute($event,'formdata')"
                                              :tabname="tab"></dynoform>
                                    <v-col cols="12" class="mx-0">
                                        <div class="form-row d-flex justify-content-center align-items-center mb-0" justify="center">
                                            <div class="form-group col-auto mb-0 text-center" justify="center">
                                                <v-img id="captchaimg" height="106" width="300" :src="captchaUrl">
                                                    <template v-slot:placeholder>
                                                        <v-skeleton-loader class="my-auto"
                                                                           type="image" height="106" width="300"></v-skeleton-loader>
                                                    </template>
                                                </v-img>
                                            </div>
                                            <div class="form-group col-auto mb-0">
                                                <v-btn depressed icon v-on:click="ReloadCaptcha()" color="secondary">
                                                    <v-icon>cached</v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div class="form-group col-12">
                                            <v-text-field v-model="Captcha"
                                                          dense
                                                          hide-details
                                                          outlined
                                                          color="accent"
                                                          append-icon="mdi-shield-check"
                                                          :class="{ fielderror: CaptchaError}"
                                                          :placeholder="$t('FL_SecurityCode')"
                                                          :label="$t('FL_SecurityCode') + '*'"
                                                          :error="CaptchaError"
                                                          type="text"></v-text-field>
                                        </div>
                                        <v-row>
                                            <v-col cols="12" class="mt-5">
                                                <table>
                                                    <tr v-for="(document, i) in documents" v-bind:key="i" class="my-0 py-0 d-flex align-center ">
                                                        <td class="d-flex align-center my-0 py-0">
                                                            <v-checkbox v-model="document.selected" v-bind:error="document.error" color="primary" class="my-0 py-0">
                                                                <template v-slot:prepend>
                                                                    <v-btn color="primary" icon v-on:click="PrepareDocumentDialog(document.name)">
                                                                        <v-icon>mdi-file-document-outline</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <template v-slot:label>
                                                                    <span class="body-2" style="padding-top: 10px">{{$t(document.name + 'Description')}}</span>
                                                                </template>
                                                            </v-checkbox>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="caption" v-html="$t('IM_ApplicationDescription')"></p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </v-col>
                                        </v-row>
                                        <div class="form-group col-12 mb-0 text-right" justify="center">
                                            <v-btn :loading="actionBarWait"
                                                   @click="PageAction()"
                                                   depressed
                                                   class="mx-2 px-4 primary white--text font-weight-medium text-capitalize">
                                                {{ $t("AL_WantToBeSupplier") }}
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
            <v-container class="p-0 px-2" v-else>
                <v-row class="d-flex justify-content-center">
                    <v-col cols="8">
                        <div class="bg-white border p-5 mt-5">
                            <alertIcon :icon="'success'" />
                            <p class="headline pb-0 mb-0" style="text-align:center">{{ $t("DM_YourApplicationReceived") }}</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-dialog v-model="actionBarWait"
                      persistent>
                <v-card color="transparent"
                        dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-main>
    </div>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>
<script>
    import Vue from 'vue';
    import Vuex from 'vuex';

    Vue.use(Vuex);


    var Tedarik_Uyum_Ortaklar = {
        tableName: 'Tedarik_Uyum_Ortaklar',
        validationMessage: 'VE_WarnFillShareholdingStructure',
        label: 'TH_PartnershipStructure',
        columns: {
            ortak: {
                type: 'text',
                name: 'ortak',
                label: 'FL_Partner',
                edit: '01234X',
                validateMin: 1,
            },
            oran: {
                type: 'number',
                name: 'oran',
                label: 'FL_Ratio',
                edit: '01234X',
                validateMin: 0.01,
            },
            tckn: {
                type: 'text',
                name: 'tckn',
                label: 'FL_VKNorTCKN',
                edit: '01234X',
                validateMin: 1
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Uyum_Ortaklar'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Uyum_Grup_Firmalari = {
        tableName: 'Tedarik_Uyum_Grup_Firmalari',
        label: 'TH_GroupCompaniesIfAny',
        columns: {
            ad: {
                type: 'text',
                name: 'ad',
                label: 'FL_CompanyTitle',
                edit: '01234X',
                validateMin: 1,
            },
            tckn: {
                type: 'text',
                name: 'tckn',
                label: 'FL_VKNorTCKN',
                edit: '01234X',
                validateMin: 1,
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Uyum_Grup_Firmalari'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Uyum_Temsilciler = {
        tableName: 'Tedarik_Uyum_Temsilciler',
        validationMessage: 'VE_WarnFillPersonsAuthorizedRepresent',
        label: 'TH_PersonsAuthorizedRepresent',
        columns: {
            adsoyad: {
                type: 'text',
                name: 'ad',
                label: 'FL_Fullname',
                edit: '01234X',
                validateMin: 1,
            },
            departmentId: {
                type: 'select',
                name: 'departmentId',
                label: 'FL_Department',
                edit: '01234X',
                options: [
                ],
                validateMin: 1,
                validateMax: 1,
            },
            phone: {
                type: 'text',
                name: 'phone',
                label: 'FL_PhoneNumber',
                edit: '01234X',
                validateMin: 10,
                validateMax: 10,
                mask: 'phone',
            },
            email: {
                type: 'text',
                name: 'email',
                label: 'FL_Email',
                edit: '01234X',
                validateMin: 1,
                mask: 'email',
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Uyum_Temsilciler'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Uyum_Yonetim_Yapisi = {
        tableName: 'Tedarik_Uyum_Yonetim_Yapisi',
        validationMessage: 'VE_WarnFillManagementStructure',
        label: 'TH_ManagementStructure',
        columns: {
            adsoyad: {
                type: 'text',
                name: 'adsoyad',
                label: 'FL_Fullname',
                edit: '01234X',
                validateMin: 1,
            },
            gorev: {
                type: 'text',
                name: 'gorev',
                label: 'FL_Position',
                edit: '01234X',
                validateMin: 1,
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Uyum_Yonetim_Yapisi'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Biggest_Customers = {
        tableName: 'Tedarik_Biggest_Customers',
        validationMessage: 'VE_WarnFillBiggestCustomers',
        label: 'TH_BiggestCustomers',
        columns: {
            firmName: {
                type: 'text',
                name: 'ad',
                label: 'FL_CompanyTitle',
                edit: '01234X',
                validateMin: 1,
            },
            scope: {
                type: 'text',
                name: 'scope',
                label: 'FL_Scope',
                edit: '01234X',
                validateMin: 1,
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'tedarik_biggest_customers'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Biggest_Financial_Customers = {
        tableName: 'Tedarik_Biggest_Financial_Customers',
        label: 'TH_FinancialCustomers',
        columns: {
            firmName: {
                type: 'text',
                name: 'ad',
                label: 'FL_CompanyTitle',
                edit: '01234X',
                validateMin: 1,
            },
            scope: {
                type: 'text',
                name: 'scope',
                label: 'FL_Scope',
                edit: '01234X',
                validateMin: 1,
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'tedarik_biggest_financial_customers'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var dynoFormInfo = {
        tabs: [
            { order: 1, header: 'TH_GeneralInformation', dontShowHeader: true }
        ],
        TabAttributes: {
            "1": { show: true }
        },
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_GeneralInformation'
            },
            {
                tab: 1,
                order: 2,
                header: 'TH_ContactInformation'
            },
            {
                tab: 1,
                order: 3,
                header: 'TH_CompanyInformation'
            },
        ],

        Columns: {
            id: {
                type: 'hidden',
                group: 1,
                sira: 0,
                name: 'id',
                label: 'ID',
                edit: '',
                dontCompare: true,
            },
            kformsid: {
                type: 'hidden',
                group: 1,
                sira: 0,
                name: 'kformsid',
                label: 'GFORMS ID',
                edit: '',
                dontCompare: true,
            },
            firmaad: {
                type: 'text',
                name: 'firmaad',
                label: 'FL_CompanyTitle',
                group: 1,
                sira: 10,
                col: '12',
                edit: '01234X',
                validateMin: 1,
            },
            yurtici: {
                type: 'select',
                name: 'yurtici',
                label: 'FL_WhichBased',
                group: 1,
                sira: 20,
                col: '6',
                edit: '01234X',
                options: [
                ],
                validateMin: 1,
                validateMax: 1,
            },

            sahis: {
                type: 'select',
                name: 'sahis',
                label: 'FL_IsSole',
                group: 1,
                sira: 30,
                col: '6',
                edit: '01234X',
                options: [
                    //Filled At Created Method
                ],
                validateMin: 1,
                validateMax: 1,
            },
            verginum: {
                type: 'text',
                name: 'verginum',
                label: 'FL_VKNorTCKN',
                group: 1,
                sira: 40,
                col: '6',
                edit: '01234X',
                validateMin: 10,
                validateMax: 11,
            },
            vergidaire: {
                type: 'text',
                name: 'vergidaire',
                label: 'FL_TaxOffice',
                group: 1,
                sira: 42,
                col: '6',
                edit: '01234X',
                validateMin: 1,
            },
            tedarikcitipi: {
                type: 'select',
                name: 'tedarikcitipi',
                label: 'FL_SupplierSector',
                group: 1,
                sira: 44,
                col: '6',
                edit: '01234X',
                options: [
                ],
                validateMin: 1,
                validateMax: 1,
            },
            kategorikey: {
                type: 'select',
                name: 'kategorikey',
                label: 'FL_SupplierCategory',
                group: 1,
                sira: 47,
                col: '6',
                edit: '01234X',
                options: [
                ],
                validateMin: 1,
                validateMax: 1,
            },
            faaliyetalani: {
                type: 'textarea',
                group: 1,
                sira: 52,
                col: '12',
                name: 'faaliyetalani',
                label: 'FL_ActivityField',
                edit: '01234X',
                validateMin: 1,
            },
            references: {
                type: 'textarea',
                group: 1,
                sira: 53,
                col: '12',
                name: 'references',
                label: 'FL_References',
                edit: '01234X',
                validateMin: 1,
            },
            diversityAlert: {
                type: 'alert',
                messageType: 'info',
                message: 'DM_diversityAlert',
                group: 1,
                sira: 54,
                col: '12',
            },
            diversityInformation: {
                type: 'select',
                name: 'diversityInformation',
                label: 'FL_DiversityInformation',
                group: 1,
                sira: 55,
                col: '6',
                edit: '01234X',
                multiple: true,
                options: [
                    //Filled At Created Method
                ],
                validateMin: 1,
            },

            sonyilcirosu: {
                type: 'number',
                name: 'sonyilcirosu',
                label: 'FL_NetSalesLastYear',
                group: 1,
                sira: 60,
                col: '4',
                edit: '01234X',
                validateMin: 1,
            },
            sonyilcirosubirimi: {
                type: 'select',
                name: 'sonyilcirosubirimi',
                label: 'FL_Currency',
                edit: '01234X',
                group: 1,
                col: '2',
                sira: 65,
                options: [
                    { text: 'TL', value: 'TL' },
                    { text: 'USD', value: 'USD' },
                    { text: 'EUR', value: 'EUR' },
                    { text: 'GBP', value: 'GBP' },
                    { text: 'QAR', value: 'QAR' }
                ],
                validateMin: 2,
            },

            kurulusyili: {
                type: 'int',
                name: 'kurulusyili',
                label: 'FL_FoundationYear',
                group: 1,
                sira: 70,
                col: '6',
                edit: '01234X',
                validateMin: 1,
            },

            numberOfEmployee: {
                type: 'int',
                group: 1,
                sira: 75,
                col: '6',
                name: 'numberOfEmployee',
                label: 'FL_NumberOfEmployee',
                edit: '01234X',
                validateMin: 1,
            },

            email: {
                type: 'text',
                name: 'email',
                label: 'FL_Email',
                group: 2,
                sira: 10,
                col: '6',
                edit: '01234X',
                mask: 'email',
                validateMin: 1,
            },
            yetkiliad: {
                type: 'text',
                name: 'yetkiliad',
                label: 'FL_CompanyRepresentative',
                group: 2,
                sira: 20,
                col: '6',
                edit: '01234X',
                validateMin: 1,
            },
            gsm: {
                type: 'text',
                name: 'gsm',
                label: 'FL_GSM',
                group: 2,
                sira: 30,
                col: '4',
                edit: '01234X',
                mask: 'phone',
                validateMin: 10,
                validateMax: 10,
            },
            tel: {
                type: 'text',
                name: 'tel',
                label: 'FL_PhoneNumber',
                group: 2,
                sira: 40,
                col: '4',
                edit: '01234X',
                mask: 'phone',
                validateMin: 10,
                validateMax: 10,
            },
            fax: {
                type: 'text',
                name: 'fax',
                label: 'FL_FaxNumber',
                group: 2,
                sira: 50,
                col: '4',
                edit: '01234X',
                mask: 'phone',
                validateMin: 10,
                validateMax: 10,
            },
            ulke: {
                type: 'auto',
                group: 2,
                sira: 60,
                col: '4',
                name: 'ulke',
                label: 'FL_Country',
                edit: '01234X',
                parameters: [],
                outputs: {
                    trulke: 'ulketr'
                },
                itemText: ['ulke'],
                searchText: 'ulke',
                validateMin: 1,
                minLength: 1,
                notAuthorizedRequest: true,
            },
            il: {
                type: 'auto',
                group: 2,
                sira: 70,
                col: '4',
                name: 'il',
                label: 'FL_City',
                edit: '01234X',
                parameters: ['*ulke'],
                outputs: {
                    sehirkodu: 'sehirkodu',
                },
                itemText: ['sehirkodu', 'il'],
                searchText: 'il',
                notAuthorizedRequest: true,
                validateMin: 1,
                minLength: 0,
            },
            ilce: {
                type: 'auto',
                group: 2,
                sira: 80,
                col: '4',
                name: 'ilce',
                label: 'FL_County',
                edit: '01234X',
                parameters: ['*ulke', '*il', '*sehirkodu'],
                outputs: {
                    ilce: 'ilce'
                },
                itemText: ['ilce'],
                searchText: 'ilce',
                notAuthorizedRequest: true,
                validateMin: 1,
                minLength: 0,
            },
            adres: {
                type: 'textarea',
                group: 2,
                sira: 90,
                col: '12',
                name: 'adres',
                label: 'FL_Address',
                edit: '01234X',
                validateMin: 1,
            },
            PrensipApprove: {
                type: 'hidden',
                name: 'PrensipApprove',
                label: '',
                edit: '',
            },
            Tedarik_Biggest_Customers: {
                type: 'grid',
                group: 3,
                sira: 3,
                name: 'Tedarik_Biggest_Customers',
                label: 'TH_BiggestCustomers',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                ],
                dontCompare: true,
                showExportToExcelButton: false,
                showaddrow: true,
                validateMin: 1,
            },
            Tedarik_Biggest_Financial_Customers: {
                type: 'grid',
                group: 3,
                sira: 5,
                name: 'Tedarik_Biggest_Financial_Customers',
                label: 'TH_FinancialCustomers',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                ],
                dontCompare: true,
                showaddrow: true,
                showExportToExcelButton: false
            },
            Tedarik_Uyum_Ortaklar: {
                type: 'grid',
                group: 3,
                sira: 10,
                name: 'Tedarik_Uyum_Ortaklar',
                label: 'TH_PartnershipStructure',
                edit: '01234X',
                col: '12',
                showselect: true,
                validateMin: 1,
                buttons: [
                ],
                showaddrow: true,
                showExportToExcelButton: false
            },
            Tedarik_Uyum_Temsilciler: {
                type: 'grid',
                group: 3,
                sira: 10,
                name: 'Tedarik_Uyum_Temsilciler',
                label: 'TH_PersonsAuthorizedRepresent',
                edit: '01234X',
                col: '12',
                showselect: true,
                validateMin: 1,
                buttons: [
                ],
                showaddrow: true,
                showExportToExcelButton: false
            },
            Tedarik_Uyum_Grup_Firmalari: {
                type: 'grid',
                group: 3,
                sira: 10,
                name: 'Tedarik_Uyum_Grup_Firmalari',
                label: 'TH_GroupCompaniesIfAny',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                ],
                showaddrow: true,
                showExportToExcelButton: false
            },
            Tedarik_Uyum_Yonetim_Yapisi: {
                type: 'grid',
                group: 3,
                sira: 10,
                name: 'Tedarik_Uyum_Yonetim_Yapisi',
                label: 'TH_ManagementStructure',
                edit: '01234X',
                col: '12',
                showselect: true,
                validateMin: 1,
                buttons: [
                ],
                showaddrow: true,
                showExportToExcelButton: false
            }
        },
        form: 'tedarik',
        formid: 0,
        formdata: {
            id: 0,
            kformsid: 0,
            state: '0',
            firmaad: '',
            yurtici: '',
            sahis: '0',
            verginum: '',
            anakategori: '',
            tedarikcitipi: '',
            kategorikey: '',
            ticaretodasifile: '',
            vergilevhasifile: '',
            gsm: '',
            tel: '',
            fax: '',
            email: '',
            yetkiliad: '',
            ulke: '',
            ulketr: '',
            il: '',
            sehirkodu: '',
            ilce: '',
            adres: '',
            captcha: '',
            faaliyetalani: '',
            Tedarik_Uyum_Ortaklar: [],
            Tedarik_Uyum_Grup_Firmalari: [],
            Tedarik_Uyum_Temsilciler: [],
            Tedarik_Uyum_Yonetim_Yapisi: [],
            Tedarik_Biggest_Customers: [],
            Tedarik_Biggest_Financial_Customers: []
        },
        viewName: 'tumu',
        viewparameter: '',

        files: [],
        extdata: [Tedarik_Uyum_Ortaklar, Tedarik_Uyum_Grup_Firmalari, Tedarik_Uyum_Temsilciler, Tedarik_Uyum_Yonetim_Yapisi, Tedarik_Biggest_Customers, Tedarik_Biggest_Financial_Customers],
        actions: ['Kaydet'],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';
    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    import { all } from 'core-js/fn/promise';
    import Utilities from '@/Utilities.js'

    function ad_AE(payload) {
        /*alert('AE')*/
    }

    export default {
        name: 'bilgilerim',
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            leftmenu,
            kview
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: { type: String, default: 'tumu' },
            drawer: { type: Boolean, default: true },
            single: { type: Boolean, default: false },
        },
        data: function () {
            return {
                valid: false,
                lazy: true,
                viewname: 'tumu',
                form: 'tedarik',
                validateError: '',
                username: '',
                formname: 'tedarik',
                depouser: false,
                accountuser: false,
                propertyeditstates: '',
                tab: 'Tab-1',
                gformsdata: Object,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                durum: '',
                Captcha: '',
                CaptchaError: false,
                captchaUrl: '/pages/BlankCaptcha.bmp',
                
                isAnyError: false,
                OperationSuccessful: false,
                ErrorText: '',
                SuccessText: '',
                documents: [
                    { selected: false, error: false, errortext: 'VE_Privacy', name: 'DM_Privacy' },
                    { selected: false, error: false, errortext: 'VE_KVKK', name: 'DM_KVKK' },
                    { selected: false, error: false, errortext: 'VE_CompanyWorknBehavior', name: 'DM_CompanyWorknBehavior' },
                ],
            };
        },
        computed: {
            GetState() {
                return this.$store.getters.GetFormState;
            },
        },
        methods: {
            FormLoaded: function () {
                return;
            },
            ReloadCaptcha: function () {
                var date = Math.floor(new Date().getTime() / 1000).toString();
                this.captchaUrl = "/api/Captcha?rnd2=" + date;
            },
            CloseWindow: function () {
                window.close();
            },
            PageAction: function () {
                var self = this;
                var err = this.ValidateForm();
                if (err.length > 0) {
                    var messages = {
                        errors: err,
                        messageType: 'E',
                        viewType: 'notification',
                    };
                    this.$root.$emit('setUpDialog', messages);
                    return;
                }

                this.actionBarWait = true;

                var val = { fieldName: 'PrensipApprove', fieldValue: true, tableName: 'formdata' };
                this.$store.commit('SetField', val);

                var accountPayload = {
                    action: "WantToBeSupplier",
                    captcha: this.Captcha,
                    formdata: {
                        formInputs: this.GetFormData,
                        extData: this.GetExtdata
                    },
                    controller: 'Login',
                    method: 'WantToBeSupplier'
                };
                this.$store.dispatch("AccountAction", accountPayload)
                    .then(response => {
                        try {
                            this.actionBarWait = false;

                            if (response.data.errorCode == 0) {
                                self.OperationSuccessful = true;
                                this.$store.commit("ClearForm");
                                return;
                            }
                            else {
                                this.ReloadCaptcha();
                                this.Captcha = '';
                                self.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                            }
                        }
                        catch (e) {
                            this.actionBarWait = false;
                            var message = this.$root.$i18n.t('EM_UnexpectedErrorTryAgain');
                            self.ShowDialog(message, 'E', 'dialog');
                            Utilities.log(" Error in fetch success function", e);
                        }
                    });
            },
            RowButtonClicked: function (payload, tableName) {
                Utilities.log(
                    ' Inventory Items RowButtonClicked',
                    payload,
                    ' Table:',
                    tableName
                );
                var button = payload.button.label;
                var id = payload.item.rowid;
                Utilities.log('Button on row:' + id + ' action:' + button);
                if (button == 'Sil')
                    this.$store.commit('RemoveRowFromExtData', {
                        tableName: tableName,
                        rowid: id
                    });
            },
            HeaderButtonClickedDyno(payload) {
                var tableName = payload.button.split(':::')[0];
                var buttonName = payload.button.split(':::')[1];
                var list = payload.list;

                Utilities.log('list >>>>> ', list);

                if (buttonName == 'Sil') {
                    for (var i = 0; i < list.length; i++) {
                        this.$store.commit('RemoveRowFromExtData', {
                            tableName: tableName,
                            rowid: list[i].rowid
                        });
                    }
                    this.message2 = { action: 'deselect', value: 0, random: Utilities.GetRandomNumber() };
                }
            },
            ChangeAttribute(e, tableName) {

                var payload = {};
                payload.attributeName = e.attributeName;
                payload.fieldName = e.column.name;
                payload.value = e.value;
                payload.tableName = tableName;
                Utilities.log("FORM Emit changeAttribute", payload)
                this.$store.commit('SetFieldAttributes', payload);
            },
            IsLegitPhoneNumber: function (value) {
                return /^\d+$/.test(value);
            },
            CustomValidate: function (value, column) {
                if (column.name == 'gsm' || column.name == 'tel' || column.name == 'fax') {
                    var yurticiOryurtdisi = this.GetFormData['yurtici'].toString().trim();

                    if (yurticiOryurtdisi == '30') {
                        column.validateMax = 0;
                    }
                    else {
                        column.validateMax = 10;
                    }

                    return this.ValidateField(value, column);
                }
                else {
                    return this.ValidateField(value, column);
                }

                return '';
            },
            ValidateForm() {
                this.ClearFieldErrors();
                for (var i in this.documents) {
                    this.documents[i].error = false;
                }
                this.CaptchaError = false;
                var errors = [];

                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;
                var attributes = this.GetFormAttributes;

                Utilities.log("formdata ::::: ", formdata);
                Utilities.log("columns ::::: ", columns);

                var needsCustomValidationFields = ['gsm', 'tel', 'fax',];
                var escapeFields = ['id', 'kformsid',];

                for (var c in columns) {
                    if (formdata[c] === undefined) {
                        formdata[c] = "";
                    }

                    var value = formdata[c].toString().trim();
                    var column = columns[c];
                    var attr = attributes[c];
                    var err = '';

                    if (attr.disabled == false) {
                        if (needsCustomValidationFields.includes(column.name)) {
                            err = this.CustomValidate(value, column);
                        }
                        else if (!escapeFields.includes(column.name)) {
                            err = this.ValidateField(value, column);
                        }

                        if (err != '') {
                            errors.push(err);

                            this.SetFieldError(c);
                            this.tab = 'Tab-1';
                        }
                    }
                }
                var temsilcilerErrors = [];
                var exdatas = dynoFormInfo.extdata;
                var isYurtici = this.GetFormField('yurtici') == "42";
                for (var j = 0; j < exdatas.length; j++) {
                    var exdata = exdatas[j];
                    var exdataColumns = this.GetExdataColumns(exdata.tableName);
                    var exdataItems = this.GetExdataItems(exdata.tableName);

                    if (exdataItems.length > 0) {
                        this.ClearFieldError(exdata.tableName);
                        for (var i = 0; i < exdataItems.length; i++) {
                            for (var col in exdataColumns) {
                                if (exdataItems[i][col] == undefined) exdataItems[i][col] = "";

                                var value = exdataItems[i][col].toString().trim();
                                var column = exdataColumns[col];

                                var err;
                                if (column.name == 'tckn') {
                                    if (isYurtici) {
                                        err = this.ValidateField(value, column);
                                    }
                                }
                                else {
                                    err = this.ValidateField(value, column);
                                }

                                var id = exdataItems[i]['rowid'];
                                if (err != '') {
                                    if (exdata.tableName != "Tedarik_Uyum_Temsilciler") {
                                        errors.push(err);
                                        this.SetExtDataFieldError(exdata.tableName, id, col);
                                    }
                                    else {
                                        temsilcilerErrors.push({ id: id, index: i, error: err, col: col });
                                    }
                                }
                                else {
                                    this.ClearExtDataFieldError(exdata.tableName, id, col);
                                }
                            }
                        }
                    }
                    else {
                        if (exdata.tableName != "Tedarik_Uyum_Grup_Firmalari" && exdata.tableName != "Tedarik_Biggest_Financial_Customers") {                            
                            errors.push(this.$root.$i18n.t(exdata.validationMessage));
                            this.SetFieldError(exdata.tableName);
                        }
                    }
                }

                if (temsilcilerErrors.length > 0) {
                    errors.push(this.$root.$i18n.t("VE_WarnPersonsAuthorizedRepresentControl"));
                    this.SetFieldError('Tedarik_Uyum_Temsilciler');

                    for (var i = 0; i < temsilcilerErrors.length; i++) {
                        this.SetExtDataFieldError('Tedarik_Uyum_Temsilciler', temsilcilerErrors[i]["id"], temsilcilerErrors[i]["col"]);
                    }
                }

                if (this.Captcha == '') {
                    this.CaptchaError = true;
                    errors.push(this.$root.$i18n.t('EM_SecurityCode'));
                }

                for (var i in this.documents) {
                    if (!this.documents[i].selected) {
                        errors.push(this.$root.$i18n.t(this.documents[i].errortext));
                        this.documents[i].error = true;
                    }
                }

                return errors;
            },

            ulke_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('ulke') == 'TÜRKİYE') {
                    this.ClearFieldDisabled('il');
                    this.SetFieldDisabled('ilce');
                }
                else {
                    this.SetFieldDisabled('il');
                    this.SetFieldDisabled('ilce');
                }

                payload.il = '';
                payload.ilce = '';

                this.$store.commit('SetFields', payload);
            },

            il_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('il') == '') {
                    this.SetFieldDisabled('ilce');
                }
                else {
                    this.ClearFieldDisabled('ilce');
                }

                payload.ilce = '';

                this.$store.commit('SetFields', payload);
            },
            yurtici_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('yurtici') == '42') {
                    this.ClearFieldDisabled('sahis');
                    this.ClearFieldDisabled('verginum');
                    this.ClearFieldDisabled('vergidaire');
                    this.SetFieldRequired('Tedarik_Uyum_Ortaklar');
                }
                else {
                    this.SetFieldDisabled('sahis');
                    this.SetFieldDisabled('verginum');
                    this.SetFieldDisabled('vergidaire');
                    this.ClearFieldRequired('Tedarik_Uyum_Ortaklar');
                }

                payload.sahis = '0';
                payload.verginum = '';

                this.$store.commit('SetFields', payload);
            },
            PrepareDocumentDialog(doc) {
                var payload = {
                    title: doc + 'Title',
                    content: doc + 'Content',
                    link: doc + 'Link'
                }
                this.$root.$emit('askAgreements', payload);
            },
        },

        created: function () {
            var lang = Utilities.GetCurrentLanguage();

            var columns = dynoFormInfo.Columns;
            var attributes = {};
            for (var key in columns) {
                if (columns.hasOwnProperty(key)) {
                    attributes[key] = {
                        items: [],
                        readonly: false,
                        hide: '',
                        error: false,
                        disabled: false,
                        approved: true,
                    };
                }
            }
            dynoFormInfo.attributes = attributes;

            dynoFormInfo.Columns.yurtici.options = [
                { text: this.$root.$i18n.t('OP_TurkeyBased'), value: '42' },
                { text: this.$root.$i18n.t('OP_Abroad'), value: '30' }
            ];

            dynoFormInfo.Columns.sahis.options = [
                { text: this.$root.$i18n.t('OP_Yes'), value: '1' },
                { text: this.$root.$i18n.t('OP_No'), value: '0' }
            ];

            Tedarik_Uyum_Temsilciler.columns.departmentId.options = [
                { text: this.$root.$i18n.t('OP_GeneralManager'), value: 1 },
                { text: this.$root.$i18n.t('OP_AssistantGeneralManager'), value: 2 },
                { text: this.$root.$i18n.t('OP_Sales'), value: 3 },
                { text: this.$root.$i18n.t('OP_Accounting'), value: 4 },
                { text: this.$root.$i18n.t('OP_Other'), value: 5 }
            ];
            let self = this;

            var req = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "tedarikci_tipi",
                notAuthorizedRequest: true
            };
            req.success = function (result) {
                if (result.data.errorCode == 0) {
                    result.data.listResult.forEach(function (obj) {
                        var listItem = {
                            text: (lang == 'en' ? obj.aciklamaEn : obj.aciklama),
                            value: obj.aciklama
                        };
                        dynoFormInfo.Columns.tedarikcitipi.options.push(listItem);
                    });
                } else {
                    self.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                }
            };
            req.error = function (err) {
                var message = this.$root.$i18n.t('EM_UnexpectedErrorTryAgain');
                self.ShowDialog(message, 'E', 'dialog');
            };

            this.$store.dispatch("GetLOV", req);

            var reqKategori = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "tedarikci_kategorisi",
                notAuthorizedRequest: true
            };
            reqKategori.success = function (result) {
                self.$store.commit("SetFormLoadingStatus", false);
                if (result.data.errorCode == 0) {
                    if (lang == 'en') {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklamaEn,
                                value: obj.key.toString(),
                            };
                            dynoFormInfo.Columns.kategorikey.options.push(listItem);
                        });
                    } else {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklama,
                                value: obj.key.toString(),
                            };
                            dynoFormInfo.Columns.kategorikey.options.push(listItem);
                        });
                    }
                } else {
                    self.ShowDialog(result.data.errorMessage, 'E', 'dialog');
                }
            };
            reqKategori.error = function (err) {
                var message = this.$root.$i18n.t('EM_UnexpectedErrorTryAgain');
                self.ShowDialog(message, 'E', 'dialog');
            };

            this.$store.dispatch("GetLOV", reqKategori);

            var reqDiversity = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "tedarikci_diversityInformation",
                notAuthorizedRequest: true
            };

            reqDiversity.success = function (result) {
                self.$store.commit("SetFormLoadingStatus", false);
                if (result.data.errorCode == 0) {
                    if (lang == 'en') {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklamaEn,
                                value: obj.key.toString(),
                            };
                            dynoFormInfo.Columns.diversityInformation.options.push(listItem);
                        });
                    } else {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklama,
                                value: obj.key.toString(),
                            };
                            dynoFormInfo.Columns.diversityInformation.options.push(listItem);
                        });
                    }
                } else {
                    this.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                }
            };
            reqDiversity.error = function (err) {
                var message = this.$root.$i18n.t('EM_UnexpectedErrorTryAgain');
                self.ShowDialog(message, 'E', 'dialog');
            };

            this.$store.dispatch("GetLOV", reqDiversity);
        },
        mounted: function ()
        {
            window.setTimeout(this.ReloadCaptcha, 1000);
            this.OperationSuccessful = false;
            this.$store.commit('updateFormInfo', dynoFormInfo);
            this.$store.commit('SetFormData', dynoFormInfo.formdata);

            this.SetFieldDisabled('il');
            this.SetFieldDisabled('ilce');
            this.SetFieldDisabled('sahis');
            this.SetFieldDisabled('verginum');
            this.SetFieldDisabled('vergidaire');

            
            //this.ReCaptcha();

            var ortaklar = {};
            ortaklar.tableName = 'Tedarik_Uyum_Temsilciler';
            ortaklar.data = [
                { departmentId: 1, id: -1 },
                { departmentId: 2, id: -2 },
                { departmentId: 3, id: -3 },
                { departmentId: 4, id: -4 },
            ];
            ortaklar.maxLen = 0;
            this.$store.commit('SetExtdataData', ortaklar);
            var items = this.GetExdataItems(ortaklar.tableName);
            for (var idx in items) {
                this.SetExtDataFieldReadOnly(ortaklar.tableName, items[idx]['rowid'], 'departmentId');
            }
        },
    }
</script>
